import React from "react";
import PropTypes from "prop-types";
import { Box, Container } from "@material-ui/core";
// import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';
import { graphql } from "gatsby";

import {
  HeroMenu,
  ListServices,
  PageMenu,
  usePageMenuStyles,
} from "components";
import { getServicesWithCategories } from "utils";

const PageHome = ({
  data: {
    config: { header },
    services,
    serviceCategories,
  },
  // location,
}) => {
  const styles = usePageMenuStyles();
  const servicesWithCategories = getServicesWithCategories(
    services,
    serviceCategories
  );

  const seoOptions = {
    // jsonLd: (
    //   <LocalBusinessJsonLd
    //     type='Contractor'
    //     id={location.href}
    //     name={name}
    //     description={defaultDescription}
    //     url={location.href}
    //     telephone={contact.phone}
    //     address={{
    //       streetAddress: address.street,
    //       addressLocality: address.locality,
    //       addressRegion: address.region,
    //       postalCode: address.zipcode,
    //       addressCountry: address.country,
    //     }}
    //     geo={geo}
    //     image={`${location.origin}/logo.png`}
    //     images={[
    //       `${location.origin}/logo.png`,
    //     ]}
    //   />
    // )
  };

  return (
    <PageMenu seo={seoOptions}>
      <HeroMenu {...header} />
      {servicesWithCategories && (
        <Box className={styles.section} component="section">
          <Container className={styles.container}>
            <ListServices hideCategories services={servicesWithCategories} />
          </Container>
        </Box>
      )}
    </PageMenu>
  );
};

PageHome.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      header: PropTypes.shape({
        context: PropTypes.string,
      }),
      id: PropTypes.string,
    }),
    services: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              draft: PropTypes.bool,
              order: PropTypes.number,
              image: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                  fluid: PropTypes.shape({
                    aspectRatio: PropTypes.number,
                    base64: PropTypes.string,
                    src: PropTypes.string,
                    srcSet: PropTypes.string,
                    sizes: PropTypes.string,
                  }),
                }),
              }),
              service: PropTypes.string,
            }),
            id: PropTypes.string,
          }),
        })
      ),
    }),
    serviceCategories: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          edges: PropTypes.arrayOf(
            PropTypes.shape({
              node: PropTypes.shape({
                fields: PropTypes.shape({
                  slug: PropTypes.string,
                }),
                frontmatter: PropTypes.shape({
                  category: PropTypes.string,
                  draft: PropTypes.bool,
                  order: PropTypes.number,
                  type: PropTypes.string,
                }),
                id: PropTypes.string,
              }),
            })
          ),
          service: PropTypes.string,
        })
      ),
    }),
  }),
};

export const homePageQuery = graphql`
  query HomePageQuery {
    config: pagesYaml(id: { eq: "PageHome" }) {
      header {
        context
      }
      id
    }
    services: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/services/" }
        frontmatter: { service: { ne: null }, draft: { eq: false } }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            draft
            order
            image {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            service
          }
          id
        }
      }
    }
    serviceCategories: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/services/" }
        frontmatter: { type: { ne: null }, draft: { eq: false } }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      group(field: frontmatter___type) {
        service: fieldValue
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              category
              draft
              order
              type
            }
            id
          }
        }
      }
    }
  }
`;

export default PageHome;
